// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;
@use "sass:color";
@import "~bootstrap/scss/bootstrap.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill-emoji/dist/quill-emoji.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "toastify-js/src/toastify.css";
@import "select2/dist/css/select2.min.css";

// light mode colors
$base-color-x: #b9c0da;
$base-color-lighter-x: color.adjust($base-color-x, $lightness: 6%);
$text-color-x: #112a46;
$text-muted-x: #3d4763;
$complement-x: #448fa3;
$complement-lighter-x: color.adjust(
    $complement-x,
    $lightness: 5%,
    $alpha: -0.2
);
$white-color-x: #efefef;
$white-transparent-4-x: color.adjust($white-color-x, $alpha: -0.4);
$white-transparent-1-x: color.adjust($white-color-x, $alpha: -0.4);
$crimson-x: #902923;
$button-x: #222e50;
$button-x-d: color.adjust($button-x, $lightness: 10%);
$link-x: rgb(170, 80, 52);
$col1-x: #f6f6f6;
$col2-x: #dbdbdb;
$yellow-x: #61560e;
$headline-x: #275388;

// dark mode colors
$base-color: #2a2c37;
$base-color-lighter: color.adjust($base-color, $lightness: 6%);
$text-color: #cbcdd9;
$text-muted: #acb7c0;
$complement: #304d6d;
$complement-lighter: color.adjust($complement, $lightness: 5%, $alpha: -0.2);
$white-color: #303030;
$white-transparent-4: color.adjust(#efefef, $alpha: -0.95);
$white-transparent-1: color.adjust(#efefef, $alpha: -0.95);
$crimson: #c81b12;
$col1: #25376a;
$col2: #1b294f;
$link: #d4a059;
$button: #dadce7;
$button-d: color.adjust($button, $lightness: 25%);
$yellow: #dbd56e;
$headline: #b1c3d1;
$gold: #d4af37;

// all

:root {
    --base-color: #{$base-color-x};
    --base-color-lighter: #{$base-color-lighter-x};
    --text-color: #{$text-color-x};
    --text-muted: #{$text-muted-x};
    --complement: #{$complement-x};
    --complement-lighter: #{$complement-lighter-x};
    --white-color: #{$white-color-x};
    --white-color-o: #{$white-color};
    --white-transparent-4: #{$white-transparent-4-x};
    --white-transparent-1: #{$white-transparent-1-x};
    --crimson: #{$crimson-x};
    --button: #{$button-x};
    --button-d: #{$button-x-d};
    --link: #{$link-x};
    --col1: #{$col1-x};
    --col2: #{$col2-x};
    --toolbar-shadow: rgb(145 145 145 / 67%);
    --toolbar-border: rgb(89, 89, 83 / 35%);
    --yellow: #{$yellow};
    --headline: #{$headline-x};
}

.dark-theme {
    --base-color: #{$base-color};
    --base-color-lighter: #{$base-color-lighter};
    --text-color: #{$text-color};
    --text-muted: #{$text-muted};
    --complement: #{$complement};
    --complement-lighter: #{$complement-lighter};
    --white-color: #{$white-color};
    --white-color-o: #{$white-color-x};
    --white-transparent-4: #{$white-transparent-4};
    --white-transparent-1: #{$white-transparent-1};
    --crimson: #{$crimson};
    --button: #{$button};
    --button-d: #{$button-d};
    --link: #{$link};
    --col1: #{$col1};
    --col2: #{$col2};
    --toolbar-shadow: rgb(0 0 0 / 25.1%);
    --toolbar-border: rgb(159, 153, 153 / 6%);
    --yellow: #{$yellow-x};
    --headline: #{$headline};
}

.btn {
    --bs-btn-border-radius: 0.1rem;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #cf5d31;
    --bs-btn-border-color: #b15428;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #df4d14;
    --bs-btn-hover-border-color: #632a12;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #dd7953;
    --bs-btn-active-border-color: #cf5d31;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #cd8d74;
    --bs-btn-disabled-border-color: #cf5d31;
}

.text-muted {
    color: var(--text-muted) !important;
}

.card {
    --bs-card-bg: var(--white-transparent-4);
    box-shadow: 3px 3px 10px #11111120;
    --bs-card-border-color: rgba(255, 0, 0, 0);
}

@font-face {
    font-family: MehrUrdu;
    src: url("../../vendors/fonts/MehrUrdu.ttf");
}

html {
    transition-duration: 500ms;
}

body {
    background-color: var(--base-color);
    color: var(--text-color);
    font-family: MehrUrdu, serif !important;
    max-width: 100%;
}

a {
    color: var(--link);
    text-decoration: none;
    &:hover {
        color: var(--link-hover);
    }
}

.container-fluid {
    padding: 0;
}

%p {
    font-size: 1.6rem;
    line-height: 4rem;
}

%h1 {
    font-size: 4.5rem;
    line-height: 8rem;
}

%h2 {
    font-size: 3rem;
    line-height: 7rem;
}

%h3 {
    font-size: 2rem;
    line-height: 6rem;
}

#content.htmx-swapping {
    opacity: 0;
    transition: opacity 1s ease-out;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: block;
    padding-left: 6px 12px;
    cursor: pointer;
    width: 150px;
    margin: auto;
    height: 30px;
    padding: 5px;
}

.cropper-container {
    max-width: 100%;
}

.edit-form {
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 3px;
    border: 1px solid rgba(231, 233, 237, 0.6);
    line-height: 3rem;
    padding: 1rem;
    &.big {
        line-height: 6rem;
        height: 90px;
        padding: 0;
    }
}

.select2-search__field {
    background-color: #11111100 !important;
    height: 2rem !important;
    line-height: 2.3rem !important;
    font-family: inherit !important;
    font-size: 1.2rem !important;
}
.select2-container--classic[dir="rtl"]
    .select2-selection--multiple
    .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px;
    font-size: 1.2rem;
}
.select2-container--classic .select2-selection--multiple {
    background-color: var(--white-transparent-4) !important;
    border: 0 !important;
    border-radius: 0px !important;
}
.select2-results__option,
.select2-container--classic[dir="rtl"]
    .select2-selection--multiple
    .select2-selection__choice__display {
    color: var(--text-color) !important;
    background: var(--base-color) !important;
}

.select2-results__option--highlighted[aria-selected] {
    background-color: var(--base-color-lighter) !important;
    color: var(--text-color) !important;
}

.select2-container--classic[dir="rtl"]
    .select2-selection--multiple
    .select2-selection__choice__remove {
    background-color: transparent;
    color: var(--text-color);
}

.select2-container--classic
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: var(--base-color-lighter) !important;
    border: 1px solid #aaa0;
}

.post-content {
    img {
        max-width: 100%;
        margin: 1rem 0px;
        padding: 0rem 1rem;
    }
}

.urduprint {
    .post-content {
        font-size: 1.2rem;
        line-height: 3rem;
    }

    white-space: normal;
    word-break: break-word;
    @at-root {
        h5#{&} {
            line-height: 3rem !important;
        }

        h4#{&} {
            line-height: 3.5rem !important;
        }

        h3#{&} {
            line-height: 4rem !important;
        }
    }

    h1 {
        line-height: 4.5rem !important;
    }
    h2 {
        line-height: 4.3rem !important;
    }
    h3 {
        line-height: 4.1rem !important;
    }
    h4 {
        line-height: 4rem !important;
    }
    h5 {
        line-height: 3.5rem !important;
    }
    p {
        line-height: 4.5rem !important;
    }
    .post .title {
        font-size: 3rem;
        line-height: 6.5rem !important;
    }
    p:has(> br) {
        height: 0.5rem;
    }
}

a.headline {
    color: var(--headline);
    text-decoration: none;
    // text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.19), 0px 8px 13px rgba(0, 0, 0, 0.12),
    //   0px 18px 23px rgba(0, 0, 0, 0.32);
    &:hover {
        color: #166dd7;
        text-decoration: none;
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.11);
    }
}
p {
    @extend %p;

    strong {
        font-weight: 900;
        text-shadow: 1px 1px 0 var(--text-color);
    }
}

@keyframes glowing {
    0% {
        color: color.adjust($crimson, $alpha: 0);
        transform: translateY(-3px);
    }
    40% {
        color: color.adjust($crimson, $alpha: -0.2);
        transform: translateY(-2px);
    }
    60% {
        color: color.adjust($crimson, $alpha: -0.3);
        transform: translateY(-1px);
    }
    0% {
        color: color.adjust($crimson, $alpha: -0.4);
        transform: translateY(0px);
    }
    100% {
        color: color.adjust($crimson, $alpha: -0.5);
        transform: translateY(1px);
    }
}

// DIFFERENT PAGES
.home {
    .card-deck {
        .btn {
            box-shadow: 0px 0px 0px red !important;
            zoom: 0.7;
        }
    }
}

.comment {
    a {
        color: var(--link) !important;
        text-decoration: none;
        &:hover {
            color: var(--link-hover) !important;
        }
    }
    &.card {
        background: var(--white-transparent-4);
        padding: 1rem 1rem;
        border-radius: 10px 10px 0 0;
    }
    .comment-date {
        font-size: 0.3rem;
        color: var(--text-muted);
    }
    .comment-body {
        font-size: 1.4rem;
        padding: 1rem 0 0 1.5rem;
        line-height: 3.3rem;
    }
    .comment-author {
        font-size: 1rem;
    }
}

.comment-footer {
    background-color: var(--base-color-lighter);
    border-radius: 0 0 10px 10px;
    border-top: 2px solid var(--bs-border-color-translucent);

    a {
        color: var(--text-muted);
    }
}

#comment {
    padding: 2rem 1rem;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.loading {
    animation: glowing 500ms infinite;
}

.ql-editor {
    direction: rtl;
    ol > li {
        list-style-type: arabic-indic;
        margin-right: 2rem;

        &::before {
            content: "";
            width: 0;
        }
    }
}

.ql-snow {
    .ql-fill,
    .ql-stroke.ql-fill {
        fill: #7484b1;
    }
    .ql-editor {
        font-size: 1.4rem;
        line-height: 4rem;
        padding: 2rem;
        direction: rtl !important;
        text-align: right !important;
        font-family: MehrUrdu, serif !important;

        &:focus {
            box-shadow: 0 6px 18px -2px var(--toolbar-shadow);
        }

        p {
            @extend %p;
        }

        h1 {
            @extend %h1;
        }

        h2 {
            @extend %h2;
        }

        h3 {
            @extend %h3;
        }
    }

    &.ql-toolbar {
        .toolbar-item {
            float: right;
        }

        .item-group {
            min-width: 300px;
        }

        .site-group {
            display: inline-block;
            white-space: nowrap;
            margin: auto;
            min-width: 170px;
        }

        .ql-picker {
            float: right;
            stroke: var(--text-color);
            color: var(--text-color);

            &.ql-expanded {
                .ql-picker-options {
                    border-color: var(--base-color-lighter);
                    background: var(--base-color-lighter);
                    padding: 0;

                    .ql-picker-item {
                        color: var(--text-color);
                        padding: 0.2rem;

                        &:hover {
                            background: var(--text-color);
                            color: var(--white-color);
                        }
                    }
                }
            }
        }

        .ql-picker-label {
            border: 1px solid transparent;
            direction: ltr !important;

            svg {
                stroke: rgb(66 66 119 / 0.3);
            }

            &.ql-active .ql-stroke {
                color: var(--link) !important;
                stroke: var(--link) !important;
            }
            &:hover {
                .ql-stroke {
                    color: var(--link) !important;
                    stroke: var(--link) !important;
                }
            }
        }

        button {
            margin: 0 1.3%;

            &.save-button {
                width: fit-content !important;
                // margin-right: 0.5rem;
                // padding: 5px 0 10px 10px !important;
                // height: 25px;
                border-radius: 3px;
                i {
                    margin: 0 5px;
                    display: inline-block;
                }
                &:hover {
                    color: var(--link);
                }
            }

            &#view {
                width: fit-content !important;
                // margin-left: 0;
                // margin-top: -2px;
                // padding: 5px 0 10px 10px!important;
                // height: 25px;
                border-radius: 3px;
                i {
                    margin: 0 5px;
                    margin-bottom: -2px;
                    display: inline-block;
                }
                &:hover {
                    color: var(--link);
                }
            }

            color: var(--text-color);

            &:hover {
                .ql-stroke {
                    color: var(--link) !important;
                    stroke: var(--link) !important;
                }
            }

            &.ql-stroke:hover {
                color: var(--link) !important;
                stroke: var(--link) !important;
            }

            &.ql-active .ql-stroke {
                color: var(--link) !important;
                stroke: var(--link) !important;
            }
        }
    }

    span {
        margin: 0 1.3%;

        &.vsep {
            width: 2px;
            background-color: var(--base-color-lighter);
        }
    }

    .ql-stroke {
        stroke: var(--text-color);
        color: var(--text-color);
    }

    .ql-tooltip {
        direction: ltr;
        padding: 0;
        font-size: 0.2rem;
        line-height: 1rem;
    }
}

@mixin toolbar-hover {
    background: linear-gradient(top, var(--col2) 0%, var(--col1) 80%);
    background: var(--col2);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, var(--col2)),
        color-stop(80%, var(--col1))
    );
    background: -webkit-linear-gradient(top, var(--col2) 0%, var(--col1) 80%);
    text-decoration: none;
    border-radius: 2px;
}

#toolbar {
    color: $text-color;
    font-family: MehrUrdu, serif !important;
    background: linear-gradient(top, var(--col1) 20%, var(--col2) 100%);
    background: var(--col1);
    z-index: 100;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(20%, var(--col1)),
        color-stop(100%, var(--col2))
    );
    background: -webkit-linear-gradient(top, var(--col1) 20%, var(--col2) 100%);
    border: 1px solid var(--toolbar-border);
    border-radius: 3px 3px 0 0;
    box-shadow: 0 6px 18px -2px var(--toolbar-shadow);

    button {
        &:hover,
        &.ql-active {
            @include toolbar-hover;
        }
    }

    span.ql-color {
        &:hover,
        &.ql-active {
            @include toolbar-hover;
        }
    }
    &.post-toolbar {
        button.toolbar-item {
            background: none;
            border: 0px;
            color: var(--text-color);
            margin: 0 0.5rem;
            &:hover {
                @include toolbar-hover;
            }
            i {
                margin-left: 0.2rem;
            }
        }
    }
}

.modal {
    --bs-modal-color: var(--text-color);
    --bs-modal-bg: var(--base-color);
    .form-control {
        background: var(--white-transparent-4);
        color: #112a46;
        color: var(--text-color);
        border: 0;
    }
}

.bi-heart-fill {
    color: $crimson-x;
}
.bi-star-fill {
    color: $gold;
}

.btn-facebook {
    background-color: #3b5998;
    color: white;
    &:hover {
        background-color: #3b5898c4;
        color: white;
    }
}
.btn-twitter {
    background-color: #1da1f2;
    color: white;
    &:hover {
        background-color: #1da1f2c4;
        color: white;
    }
}
.btn-whatsapp {
    background-color: #25d366;
    color: white;
    &:hover {
        background-color: #25d366c4;
        color: white;
    }
}
.btn-email {
    background-color: #f3f3f3;
    color: black;
    &:hover {
        background-color: #f3f3f3c4;
        color: black;
    }
}
.btn-link {
    background-color: rgb(76, 76, 76);
    color: white;
    &:hover {
        background-color: rgba(76, 76, 76, 0.845);
        color: white;
        border: 1px solid white;
    }
}
.btn-others {
    background-color: rgb(27, 116, 91);
    color: white;
    &:hover {
        background-color: rgba(27, 116, 91, 0.845);
        color: white;
    }
}

.page-link,  .page-link.disabled, .disabled>.page-link {
    color: var(--text-muted);
    background-color: transparent;
    border: 0;
}

.pagination .page-link[aria-label="Previous"] {
    border-top-right-radius: 0.25rem; /* Remove border radius from top right corner */
    border-bottom-right-radius: 0.25rem; /* Remove border radius from bottom right corner */
    border-top-left-radius: 0; /* Add border radius to top left corner */
    border-bottom-left-radius: 0; /* Add border radius to bottom left corner */
}

.pagination .page-link[aria-label="Next"] {
    border-top-right-radius: 0; /* Add border radius to top right corner */
    border-bottom-right-radius: 0; /* Add border radius to bottom right corner */
    border-top-left-radius: 0.25rem; /* Remove border radius from top left corner */
    border-bottom-left-radius: 0.25rem; /* Remove border radius from bottom left corner */
}

#writer {
    min-height: 500px;
    padding: 0;
    background: var(--white-transparent-4);
    background: linear-gradient(
        0deg,
        var(--white-transparent-1) 0%,
        var(--white-transparent-4) 100%
    );
    border: 2px solid var(--white-transparent-4);
}

.count-container {
    background-color: rgb(255 255 255 / 1%);
    color: var(--text-muted);
}

.sticky-header {
    position: fixed !important;
    top: 0;
    z-index: 999;
    box-shadow: 0 0 10px var(--base-color-lighter);
}

.form-control {
    background: var(--white-transparent-4);
    color: var(--text-color);
    border: 0;
    border-radius: 0;
    padding: 0 2rem;
    height: 4rem;
    font-size: 1.7rem;

    &:focus {
        background: var(--white-transparent-4);
        color: var(--text-color);
        border: 1px solid color.adjust($yellow, $alpha: -0.6);
    }
}

textarea {
    &.form-control {
        // min-height: calc(2em + 2.85rem + 2px);
        height: fit-content;
        &#post-title {
            font-size: 3rem;
            line-height: 6rem;
        }
    }
}

.popover {
    z-index: 99999;
}

.floaty {
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 160px;
    z-index: 999;

    .btn-circle {
        width: 50px;
        margin-right: 100px;
        height: 50px;
        padding: 17px 16px 12px 16px;
        border-radius: 60px;
        font-size: 15px;
        text-align: center;
        background: var(--button);
        box-shadow: 0px 3px 8px rgba(17, 17, 17, 0.367),
            0px 3px 18px rgba(17, 17, 17, 0.267);
        color: var(--white-color);
        margin-top: 15px;
        border: 0;
    }

    .btn-circle:hover:hover {
        background: var(--button-d);
        color: var(--yellow);
        box-shadow: 0px 3px 12px rgba(17, 17, 17, 0.367),
            0px 3px 14px rgba(17, 17, 17, 0.367);
    }
}

.fixed-logo {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 9999;
}

// smal screens
@media (max-width: 992px) {
    .fixed-logo {
        position: fixed;
        top: 5.2rem;
        left: 1rem;
        right: auto;
        z-index: 9999;
    }

    .floaty {
        top: auto;
        left: 1rem;
        bottom: 2rem;
    }

    .btn-circle-menu {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        width: 50px;
        margin-right: 100px;
        height: 50px;
        padding: 0px;
        border-radius: 60px;
        font-size: 15px;
        text-align: center;
        box-shadow: 0px 3px 8px rgba(17, 17, 17, 0.367),
            0px 3px 18px rgba(17, 17, 17, 0.267);
        color: var(--white-color);
        margin-top: 15px;
        border: 0;
        background-color: rgba(255, 255, 255, 0);
    }

    .btn-circle {
        transition: all 0.3s ease-in-out;
        width: 30px;
        height: 30px;
        padding: 9px 8px 6px 8px;
    }
}

.pagination {
    --bs-pagination-disabled-bg: rgba(255, 255, 255, 0.36);
    --bs-pagination-bg: rgba(255, 255, 255, 0.73);
    overflow: scroll;
}

// big screens
@media (min-width: 768px) {
    // .writing-container {
    //   padding-left: 3rem !important;
    // }
}

@media print {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    a {
        color: #1d1d1d;
    }
    .urduprint p {
        font-size: 1.2rem !important;
        line-height: 3.1rem !important;
    }
    p {
        font-size: 1.2rem !important;
        line-height: 3.1rem !important;
        margin: 0rem 0 !important;
    }
    .display-1 {
        font-size: 3rem !important;
        line-height: 4rem !important;
    }
    h1 {
        font-size: 2.5rem !important;
        line-height: 3.5rem !important;
    }
    h2 {
        font-size: 2rem !important;
        line-height: 3rem !important;
    }
    h3 {
        font-size: 1.8rem !important;
        line-height: 2.8rem !important;
    }
    h4 {
        font-size: 1.6rem !important;
        line-height: 2.6rem !important;
    }
    h5 {
        font-size: 1.4rem !important;
        line-height: 1.4rem !important;
    }
    .noprint {
        visibility: hidden;
    }
}

.toastify {
    background: linear-gradient(180deg, #489471, #3c7d5f);
    min-width: 120px;
    padding: 12px 20px 5px 20px;
    border-radius: 3px;
}

.english {
    font-family: Arial, Helvetica, sans-serif;
    direction: ltr;
}

.signup-form input {
    font-family: sans-serif;
    direction: ltr;
    width: 100%;
}

.login-form input {
    font-family: sans-serif;
    direction: ltr;
    width: 100%;
}
.btn {
    padding-top: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
.btn.btn-default {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
}
.btn.btn-flat {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    opacity: 0.5;
    color: var(--link-color);
}
.btn-full {
    width: 100%;
}
.shadow1:hover {
    box-shadow: 0px 15px 20px rgba(37, 37, 37, 0.4);
    transform: translateY(-2px);
    transition-duration: 100ms;
}

.fadeout {
    opacity: 0;
    transition: opacity 0.2s;
}
.fadein {
    opacity: 1;
    transition: opacity 0.2s;
}
#cheez {
    color: #2969b4 !important;
}
#loag {
    color: #c43e63 !important;
}
.krtab-header {
    line-height: 14rem;
    width: 11rem;
    text-align: center;
}

.broder-bot {
    border-bottom: 5px solid #e07065;
    margin-bottom: 1rem;
}
.height-12 {
    height: 12rem;
}
.broder-bot img {
    margin-top: -4rem;
}
